var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",staticStyle:{"margin-top":"0"}},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"prepend-icon":"mdi-navigation","items":[
            { id: 1, nombre: 'Calendario' },
            { id: 2, nombre: 'Financiero' } ],"item-text":"nombre","item-value":"id","label":"Tipo de Reporte","color":"blue darken-3"},model:{value:(_vm.opcionReporte),callback:function ($$v) {_vm.opcionReporte=$$v},expression:"opcionReporte"}})],1),(_vm.opcionReporte != 2)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de Inicio","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.inicio_formated),callback:function ($$v) {_vm.inicio_formated=$$v},expression:"inicio_formated"}},'v-text-field',attrs,false),on))]}}],null,false,2936695427),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"es-cl","prev-icon":"chevron_left","next-icon":"chevron_right"},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.inicio),callback:function ($$v) {_vm.inicio=$$v},expression:"inicio"}})],1)],1):_vm._e(),(_vm.opcionReporte != 2)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de termino","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.termino_formated),callback:function ($$v) {_vm.termino_formated=$$v},expression:"termino_formated"}},'v-text-field',attrs,false),on))]}}],null,false,3317772643),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"es-cl","prev-icon":"chevron_left","next-icon":"chevron_right"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.termino),callback:function ($$v) {_vm.termino=$$v},expression:"termino"}})],1)],1):_vm._e(),(_vm.opcionReporte == 2)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"prepend-icon":"mdi-navigation","items":_vm.cierres,"item-text":"nombre","item-value":"id","label":"Elija periodo de cierre","color":"blue darken-3","return-object":""},on:{"change":_vm.setCierre},model:{value:(_vm.cierre),callback:function ($$v) {_vm.cierre=$$v},expression:"cierre"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"prepend-icon":"mdi-navigation","items":[
            { id: 0, nombre: 'Todos' },
            { id: 1, nombre: 'Cosecha' },
            { id: 3, nombre: 'Smolt' },
            ,
            { id: 4, nombre: 'Mortalidad' },
            { id: 5, nombre: 'Baño' },
            { id: 6, nombre: 'Vacuna' } ],"item-text":"nombre","item-value":"id","label":"Filtro Reporte","color":"blue darken-3"},model:{value:(_vm.opcionFiltro),callback:function ($$v) {_vm.opcionFiltro=$$v},expression:"opcionFiltro"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"blue darken-1","dark":""},on:{"click":_vm.loadFacturas}},[_c('i',{staticClass:"material-icons"},[_vm._v("cached")]),_vm._v(" Generar")]),_c('v-btn',{staticClass:"mx-2",attrs:{"dark":"","color":"green"},on:{"click":_vm.download}},[_c('i',{staticClass:"material-icons"},[_vm._v("text_snippet")]),_vm._v(" Excel")])],1)],1),(_vm.spinner)?_c('spinner'):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.facturasListHeaders,"items":_vm.facturasListBody,"no-data-text":"No hay partes","search":_vm.search,"fixed-header":"","height":_vm.table_height,"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.emision",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.emision).format("DD-MM-YYYY")))])]}},{key:"item.vencimiento",fn:function(ref){
          var item = ref.item;
return [(item.vencimiento)?_c('span',[_vm._v(_vm._s(_vm.moment(item.vencimiento).format("DD-MM-YYYY")))]):_vm._e()]}},{key:"item.fecha",fn:function(ref){
          var item = ref.item;
return [(item.fecha)?_c('span',[_vm._v(_vm._s(_vm.moment(item.fecha).format("DD-MM-YYYY")))]):_vm._e()]}},{key:"item.peces_centro",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.peces_centro, 0))+" ")]}},{key:"item.talla_pez_centro",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.talla_pez_centro, 2))+" ")]}},{key:"item.biomasa",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.biomasa, 0))+" ")]}},{key:"item.tarifa_usd",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.tarifa_usd, 0))+" ")]}},{key:"item.servicio_usd",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.servicio_usd, 0))+" ")]}}])})],1),_c('v-snackbar',{attrs:{"timeout":5000,"color":_vm.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.validation_message)+" ")])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header card-header-icon card-header-rose"},[_c('div',{staticClass:"card-icon"},[_c('i',{staticClass:"material-icons"},[_vm._v("notes")])]),_c('h3',{staticClass:"card-title"},[_vm._v("REPORTE DE FACTURACION")])])}]

export { render, staticRenderFns }